<template>
	<div class="addTemplate">

		<el-card class="box-card">
			<div class="infortion">模板信息</div>
			<!-- 表单 -->
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
				<el-form-item label="运费模板名称" prop="TemplateName">
					<el-input v-model="ruleForm.TemplateName" style="width:250px;"></el-input>
				</el-form-item>

				
				<el-form-item label="计费方式" prop="FreightTemplateType" required>
						<el-radio v-model="ruleForm.FreightTemplateType" :label="0" :disabled='Boolean(templateId)'>按件计费</el-radio>
						<el-radio v-model="ruleForm.FreightTemplateType" :label="1" :disabled='Boolean(templateId)'>按重量计费</el-radio>
				</el-form-item>

				<el-form-item label="运费设置">
					<span class="lml_tips">除指定配送区域,其他区域采用<span style="color:red">全国默认运费</span></span>

					<el-table :data="tableData" border style="width: 100%;margin-top:20px">
						<el-table-column prop="areas" label="配送区域" width="500px" :key="1">
							<template slot-scope="scope">
								<el-tag v-for="(tag,index1) in scope.row.AreaNames" :key="index1" style="margin:0 10px 10px 10px" :closable="scope.row.iShows"
								 :disable-transitions="false" @close="handleClose(tag,scope.row,1)">
									{{tag}}
								</el-tag>
							</template>
						</el-table-column>

						<el-table-column prop="areaPrice" label="首件(个)" :key="2" v-if="!ruleForm.FreightTemplateType">
							<template slot-scope="scope">
								<el-input	
									type="text" 
									v-model="scope.row.FirstCountToNumber"
									@input='scope.row.FirstCountToNumber=validateInt(scope.row.FirstCountToNumber)'
									style="width: 100px;"
								>
								</el-input>			
							</template>
						</el-table-column>

						<el-table-column prop="areaPrice" label="运费(元)" :key="3" v-if="!ruleForm.FreightTemplateType">
							<template slot-scope="scope">
								<el-input	
									type="text" 
									v-model="scope.row.FirstMoneyToNumber"
									@input='scope.row.FirstMoneyToNumber=validateDecimal(scope.row.FirstMoneyToNumber,2)'
									style="width: 100px;"
								>
								</el-input>	
							</template>
						</el-table-column>

						<el-table-column prop="areaPrice" label="续件(个)" :key="4" v-if="!ruleForm.FreightTemplateType">
							<template slot-scope="scope">
								<el-input	
									type="text" 
									v-model="scope.row.ContinueCountToNumber"
									@input='scope.row.ContinueCountToNumber=validateInt(scope.row.ContinueCountToNumber)'
									style="width: 100px;"
								>
								</el-input>		
							</template>
						</el-table-column>

						<el-table-column prop="areaPrice" label="续费(元)" :key="5" v-if="!ruleForm.FreightTemplateType">
							<template slot-scope="scope">
								<el-input	
									type="text" 
									v-model="scope.row.ContinueMoneyToNumber"
									@input='scope.row.ContinueMoneyToNumber=validateDecimal(scope.row.ContinueMoneyToNumber,2)'
									style="width: 100px;"
								>
								</el-input>	
							</template>
						</el-table-column>

						<el-table-column prop="areaPrice" label="首重(KG)" :key="6" v-if="ruleForm.FreightTemplateType">
							<template slot-scope="scope">
								<el-input	
									type="text" 
									v-model="scope.row.FirstWeightToNumber"
									@input='scope.row.FirstWeightToNumber=validateDecimal(scope.row.FirstWeightToNumber,3)'
									style="width: 100px;"
								>
								</el-input>	
							</template>
						</el-table-column>

						<el-table-column prop="areaPrice" label="运费(元)" :key="7" v-if="ruleForm.FreightTemplateType">
							<template slot-scope="scope">
								<el-input	
									type="text" 
									v-model="scope.row.FirstWeightMoney"
									@input='scope.row.FirstWeightMoney=validateDecimal(scope.row.FirstWeightMoney,2)'
									style="width: 100px;"
								>
								</el-input>	
							</template>
						</el-table-column>
						
						<el-table-column prop="areaPrice" label="续重(KG)" :key="8" v-if="ruleForm.FreightTemplateType">
							<template slot-scope="scope">
								<el-input	
									type="text" 
									v-model="scope.row.ContinueWeightToNumber"
									@input='scope.row.ContinueWeightToNumber=validateDecimal(scope.row.ContinueWeightToNumber,3)'
									style="width: 100px;"
								>
								</el-input>	
							</template>
						</el-table-column>

						<el-table-column prop="areaPrice" label="续费(元)" :key="9" v-if="ruleForm.FreightTemplateType">
							<template slot-scope="scope">
								<el-input	
									type="text" 
									v-model="scope.row.ContinueWeightMoney"
									@input='scope.row.ContinueWeightMoney=validateDecimal(scope.row.ContinueWeightMoney,2)'
									style="width: 100px;"
								>
								</el-input>	
							</template>
						</el-table-column>

						<el-table-column label="操作" width="130" :key="10">
							<template slot-scope="scope">
								<el-button type="text" class="addClass" @click="addArefun(scope.row,1,scope.$index)" v-if="scope.row.iShows">修改</el-button>
								<span style="color:red;cursor: pointer;" @click="delectFright(scope.row,scope.$index)" v-if="scope.row.iShows">删除</span>
							</template>
						</el-table-column>

					</el-table>
					<div class="addArea" >
						<el-button type="text" @click="addArefun({},1)">添加配送区域</el-button>
					</div>
				</el-form-item>

				<el-form-item>
					<el-table :data="noDeliveryData" border style="width: 100%;margin-top:20px">
						<el-table-column label="不配送区域"  :key="11">
							<template slot-scope="scope">
								<span v-if="!scope.row.AreaNames.length" style="color:#909399;">暂无不配送区域</span>
								<el-tag v-for="(tag,index) in scope.row.AreaNames" v-else :key="tag + index" style="margin:0 10px 10px 10px" 
								 :disable-transitions="false" closable @close="handleCloseNo(index)">
									{{tag}}
								</el-tag>
								<span v-else>暂无不配送区域</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="130" :key="12">
							<template slot-scope="scope">
								<el-button type="text" @click="editNoDelivery" >修改</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</el-form>
		</el-card>


		<!-- 选择包邮地区 -->
		<el-dialog :title="titleShows" :visible.sync="selectShipShow" width="900px" v-if="selectShipShow">
			<select-ship :defaultData='showAreaIds' @getCheckedArea='getCheckedArea' :excludeData='excludeData'></select-ship>
		</el-dialog>

		<div class="footer-tools">
			<el-button style="width:200px;margin-right: 10px;" @click="canselBtn">取 消</el-button>
			<el-button type="primary" style="width:200px" @click="saveTemptet('ruleForm')" :loading="saveLoading" :disabled="saveDisable">保存</el-button>
		</div>

	</div>
</template>

<script>
	import {validateInt,validateDecimal} from '@/utils/validate';
	import selectShip from '../../market/discountActivity/selectShipDialog.vue';
	import axios from 'axios'
	import {
		freightTemplateSave,
		freightTemplateinfo

	} from '@/api/goods'
	export default {
		components: {
			selectShip
		},
		data() {
			return {
				typeStyle: 'primary',
				saveDisable: false,
				jurisdictionList: [],
				menuList: [],
				SetDialogVisible: false,
				templateId: null,
				tableData: [{
					AreaNames: ['全国默认运费（不含港澳台）'],
					AreaIds: [-1],
					iShows: false,
					FirstCountToNumber:null,
					FirstMoneyToNumber: null,
					ContinueCountToNumber: null,
					ContinueMoneyToNumber:null,
					FullCount: null,
					SendTypeId: 0,
					TypeName: '快递',
					areas: [{
						Id: -1,
						AreaId: -1,
						Name: '全国默认运费（不含港澳台）'
					}]
				}],
				ruleForm: {
					TemplateName: '',
					FreightTemplateType: 0,
					IsFree: false,
				},
				rules: {
					TemplateName: [{
						required: true,
						message: '请填写运费模板名称',
						trigger: 'blur'
					}],

				},
				recordData: {},
				saveLoading: false,
				selecttype: '',
				selectShipShow: false,
				showAreaIds: [],
				
				titleShows: '',
				isSelectNoDeliver: true,
				noDeliveryData: [
					{
						AreaNames: [],
						AreaIds: [],
					}
				],
				noDeliveryAreaIds: [
				],

				excludeData:[]

			}
		},
		beforeMount() {
			this.templateId = this.$route.query.templateId;
			this.getDataList()
			this.getDat();

		},
		methods: {
			validateDecimal(num,decimal) {
				return validateDecimal(num,decimal)
			},
			validateInt(num) {
				return validateInt(num)
			},
			// validateInt,
			getCheckedArea(ids, names) {
				if(this.isSelectNoDeliver) {
					this.noDeliveryData = [
						{
							AreaNames: names,
							AreaIds: ids,
						}
					]
				}else{
					if (this.selecttype == 1) {
						let obj = {
							AreaNames: names,
							AreaIds: ids,
							uid: this.guid(),
							iShows: true,
							FirstCountToNumber:null,
							FirstMoneyToNumber:null,
							ContinueCountToNumber:null,
							ContinueMoneyToNumber:null,
							FullCount: null,
							SendTypeId: 0,
							TypeName: '快递'
						}
						if (this.recordData.uid) {
							this.tableData = this.tableData.map(its => {
								if (its.uid == this.recordData.uid) {
									its.AreaNames = names
									its.AreaIds = ids
								}
								return its
							})

						} else if (obj.AreaNames.length) {
							this.tableData.push(obj)
						}
					}
				}
				this.selectShipShow = false;
			},
			//获取地址数据
			getDataList() {
				var that = this
				axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v='+new Date().getTime(), {})
					.then(function(response) {
						that.menuList = response.data
						// console.log(that.menuList)
					})
					.catch(function(error) {
						console.log(error)
					})
			},

			// 删除运费
			delectFright(record, index) {
				this.tableData.splice(index, 1)
			},

			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

			},

			//初始化数据
			async getDat() {
				this.loading = true;
				try {
					if (this.templateId > 0) {
						let result = await freightTemplateinfo({
							Id: this.templateId
						});

						this.ruleForm.TemplateName = result.Result.TemplateName;
						this.ruleForm.FreightTemplateType= result.Result.FreightTemplateType;

						if (result.Result.DetailList.length) {
							this.tableData = result.Result.DetailList;
							this.tableData.map(item => {								
								item.uid = this.guid();
								if (item.AreaNames[0].indexOf("全国默认运费") == -1) {
									item.iShows = true;

								} else {
									item.iShows = false;
								}
							})
						}

						this.noDeliveryData = [
							{
								AreaNames: result.Result.NonDistributionAreaNames,
								AreaIds: result.Result.NonDistributionAreaIds,
							}
						]
					}

				} catch (error) {
					this.typeStyle = 'info'
					// this.saveDisable = true
					console.log(error);
				} finally {
					this.loading = false;
				}

			},

			// 数组去重
			unique(arr) {

				const res = new Map();
				return arr.filter((arr) => !res.has(arr.Id) && res.set(arr.Id, 1))
			},

			// 删除标签
			handleClose(record, datas, type) {
				let delectId=null;
				this.menuList.forEach(item=> {
					if(item.name== record){
						delectId= item.id
					}
					if(item.child && item.child.length) {
						item.child.forEach (tt => {
							if(tt.name == record){
								delectId = tt.id
							}
						})
					}
				})
				
				if (type == 1) {
					this.tableData.map(item => {
						if (datas.uid == item.uid) {
							item.AreaNames = item.AreaNames.filter(reds => {
								return reds !== record
							})
							
							item.AreaIds = item.AreaIds.filter(record => {
								return record != delectId
							})
						}
						return item
					})
				}
				console.log("	this.tableData",	this.tableData)
			},
			// 删除不配送标签
			handleCloseNo(index) {
				this.noDeliveryData[0].AreaNames.splice(index,1)
				this.noDeliveryData[0].AreaIds.splice(index,1)
			},

			// 添加不配送区域
			editNoDelivery() {
				let tableData = this.tableData.filter((t,i)=> {
					return i!=0
				})

				this.excludeData = []
				tableData.forEach(t=>{
					this.excludeData = this.excludeData.concat(t.AreaIds)
				})

				let record= this.noDeliveryData[0]
				this.showAreaIds = (record.AreaIds && record.AreaIds.length) ? record.AreaIds : []
				
				this.isSelectNoDeliver = true
				this.selectShipShow = true
				this.titleShows = '不配送区域'

			},
			// 添加配送区域
			async addArefun(record, type,index) {
				this.selecttype = type;
				this.recordData = record;
				this.showAreaIds = (record.AreaIds && record.AreaIds.length) ? record.AreaIds : []

				let tableData = this.tableData.filter((t,i)=> {
					return i!=0 && i!=index
				})

				this.excludeData = []
				tableData.forEach(t=>{
					this.excludeData = this.excludeData.concat(t.AreaIds)
				})

				this.excludeData= this.excludeData.concat(this.noDeliveryData[0].AreaIds)
				
				console.log('tableData1',this.excludeData,this.noDeliveryData[0].AreaIds)

				this.titleShows = '配送区域'
				this.isSelectNoDeliver = false
				this.selectShipShow = true
			},
			
			// 保存运费模板
			saveTemptet(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {

						this.saveLoading = true;
						try {

							let arrys1 = [] //首件验证
							let arrys2 = [] //首重验证

							let arrys3 = [] //配送区域必填
							this.tableData.map(items => {

								if (items.FirstCountToNumber < 1 && !this.ruleForm.FreightTemplateType) {
									arrys1.push(items)
								}
								
								if((items.FirstWeightToNumber < 0.001||items.FirstWeightToNumber >999.999 ||!items.FirstWeightToNumber)&&  this.ruleForm.FreightTemplateType) {
									arrys2.push(items)
								}

								if((items.FirstCountToNumber || items.FirstWeightToNumber )&& !items.AreaNames.length) {
									arrys3.push(items)
								}
							})

							if (arrys1.length) {
								this.$message({showClose: true,
									type: 'error',
									message: '首件不能为空!'
								});
								this.saveLoading = false
								return
							}
							
							if (arrys2.length) {
								this.$message({showClose: true,
									type: 'error',
									message: '首重必须为0.001~999.999内数字'
								});
								this.saveLoading = false
								return
							}

							if (arrys3.length) {
								this.$message({showClose: true,
									type: 'error',
									message: '请选择配送区域'
								});
								this.saveLoading = false
								return
							}


							let data = {
								Id: this.templateId ? this.templateId : 0,
								TemplateName: this.ruleForm.TemplateName,
								FreightTemplateType: this.ruleForm.FreightTemplateType,
								DetailList: this.tableData,
								NonDistributionAreaIds: this.noDeliveryData[0].AreaIds,
								NonDistributionAreaNames: this.noDeliveryData[0].AreaNames
							}


							let result = await freightTemplateSave(data);
							if (result.IsSuccess) {
								this.$message({showClose: true,
									type: 'success',
									message: '操作成功'
								});

								this.saveLoading = false;
								this.$router.push({
									path: '/order/shippingTemplates'
								});
							}

						} catch (e) {
							console.log(e)
						} finally {
								this.saveLoading = false

						}

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 取消事件
			canselBtn() {
				this.$router.push({
					path: '/order/shippingTemplates'
				});
			},
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			}


		}
	}
</script>

<style lang="less">
	.addTemplate {
		.box-card {
			margin-bottom: 100px;
		}
		.addClass {
			margin-right: 10px;
		}

		.infortion {
			margin-bottom: 20px;
			padding: 10px 0;
			border-bottom: 1px solid #ccc;
		}

		.lml_tips {
			color: #999;
			font-size: 14px;
			padding-left: 15px;
		}

		.addArea {
			color: blue;
			font-size: 14px;
			width: 100%;
			border: 1px solid #ddd;
			padding: 5px 15px;
			cursor: pointer;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.footer-tools {
			box-shadow: 13px 1px 6px #999;
			line-height: 80px;
			background-color: #fff;
			position: fixed;
			bottom: 0px;
			width: 100%;
			text-align: center;
			z-index: 999;
		}
	}
</style>
